<template>
  <div>
    <b-modal
      scrollable
      ref="modal-gestacaoUpdate"
      hide-footer
      centered
      id="gestacao-update"
      title="Atualizar Gestação Atual"
    >
      <div>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="cadastrarGestacao">
            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Data da gestação (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Data"
                  rules="required"
                >
                  <v-text-field
                    type="Date"
                    v-model="dataGestacao"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <h6 style="color: grey">Queixa (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Queixa"
                  rules="required"
                >
                  <v-text-field
                    v-model="queixa"
                    :error-messages="errors"
                    color="red"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">IG -DUM/USG (Obrigatório)</h6>
                <validation-provider name="IG -DUM/USG" rules="required">
                  <v-text-field
                    v-model="igDum"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Peso (kg)/IMC (Obrigatório)</h6>
                <validation-provider name="Peso (kg)/IMC" rules="required">
                  <v-text-field
                    v-model="peso"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Edema (Obrigatório)</h6>
                <validation-provider name="Edema" rules="required">
                  <b-form-select
                    class="mt-2"
                    v-model="edema"
                    :error-messages="errors"
                    :options="optionsGestacao"
                  ></b-form-select>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">
                  Pressão arerial (mmHg) (Obrigatório)
                </h6>
                <validation-provider
                  name="Pressão arerial (mmHg)"
                  rules="required"
                >
                  <v-text-field
                    v-model="pressaoArterial"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Altura uterina (cm) (Obrigatório)</h6>
                <validation-provider
                  name="Altura uterina (cm)"
                  rules="required"
                >
                  <v-text-field
                    v-model="alturaUterina"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Apresentação fetal (Obrigatório)</h6>
                <validation-provider name="Apresentação fetal" rules="required">
                  <v-text-field
                    v-model="apresentacaoFetal"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">BCF / Mov. fetal (Obrigatório)</h6>
                <validation-provider name="BCF / Mov. fetal" rules="required">
                  <v-text-field
                    v-model="bfcMovFetal"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Toque, se indicado (Obrigatório)</h6>
                <validation-provider name="Toque, se indicado" rules="required">
                  <b-form-select
                    class="mt-2"
                    v-model="toqueIndicado"
                    :error-messages="errors"
                    :options="optionsGestacao"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">
                  Exantema (presença ou relato) (Obrigatório)
                </h6>
                <validation-provider
                  name="Exantema (presença ou relato)"
                  rules="required"
                >
                  <b-form-select
                    class="mt-2"
                    v-model="exantema"
                    :error-messages="errors"
                    :options="optionsGestacao"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModalUpdate"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              :disabled="invalid"
              style="float: right"
              @click="updateGestacao"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Atualizar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>
    <b-modal
      scrollable
      ref="modal-gestacao"
      hide-footer
      centered
      id="gestacao-1"
      title="Cadastrar Gestação Atual"
    >
      <div>
        <validation-observer ref="observer2" v-slot="{ invalid }">
          <form @submit.prevent="cadastrarGestacao">
            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Data da gestação (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Data"
                  rules="required"
                >
                  <v-text-field
                    type="Date"
                    v-model="dataGestacao"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>

              <div class="col-md-6">
                <h6 style="color: grey">Queixa (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Queixa"
                  rules="required"
                >
                  <v-text-field
                    v-model="queixa"
                    :error-messages="errors"
                    color="red"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">IG -DUM/USG (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="IG -DUM/USG"
                  rules="required"
                >
                  <v-text-field
                    v-model="igDum"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Peso (kg)/IMC (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Peso (kg)/IMC"
                  rules="required"
                >
                  <v-text-field
                    v-model="peso"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Edema (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Edema"
                  rules="required"
                >
                  <b-form-select
                    class="mt-2"
                    v-model="edema"
                    :error-messages="errors"
                    :options="optionsGestacao"
                  ></b-form-select>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">
                  Pressão arerial (mmHg) (Obrigatório)
                </h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Pressão arerial (mmHg)"
                  rules="required"
                >
                  <v-text-field
                    v-model="pressaoArterial"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">Altura uterina (cm) (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Altura uterina (cm)"
                  rules="required"
                >
                  <v-text-field
                    v-model="alturaUterina"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Apresentação fetal (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Apresentação fetal"
                  rules="required"
                >
                  <v-text-field
                    v-model="apresentacaoFetal"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h6 style="color: grey">BCF / Mov. fetal (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="BCF / Mov. fetal"
                  rules="required"
                >
                  <v-text-field
                    v-model="bfcMovFetal"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <h6 style="color: grey">Toque, se indicado (Obrigatório)</h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Toque, se indicado"
                  rules="required"
                >
                  <b-form-select
                    class="mt-2"
                    v-model="toqueIndicado"
                    :error-messages="errors"
                    :options="optionsGestacao"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h6 style="color: grey">
                  Exantema (presença ou relato) (Obrigatório)
                </h6>
                <validation-provider
                  v-slot="{ errors }"
                  name="Exantema (presença ou relato)"
                  rules="required"
                >
                  <b-form-select
                    class="mt-2"
                    v-model="exantema"
                    :error-messages="errors"
                    :options="optionsGestacao"
                  ></b-form-select>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModal"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              :disabled="invalid"
              style="float: right"
              @click="cadastrarGestacao"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>

    <b-modal
      scrollable
      ref="filter-modal"
      hide-footer
      centered
      id="modal-dataPreNatal"
      title="Filtrar pré-natal por intervalo de data"
    >
      <div class="row">
        <div class="col-md-5">
          <v-text-field v-model="consultaDataUm" type="Date"></v-text-field>
        </div>
        <div class="col-md-5">
          <v-text-field v-model="consultaDataDois" type="Date"></v-text-field>
        </div>
        <div class="col-md-2">
          <v-btn
            @click="consultarPorIntervalo"
            v-b-tooltip.hover.top="'Filtrar'"
            color="#1dd1a1"
            class="mr-4 mb-2 mt-4"
          >
            <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
          >
        </div>
      </div>
    </b-modal>

    <b-modal
      scrollable
      ref="modal-novo-prenatal"
      hide-footer
      centered
      id="modal-novo-prenatal"
      title="Cadastrar novo Pré-natal"
    >
      <!--begin::prontuario-->

      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="row">
            <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
              <h3 style="float: left" class="textTitile mt-4 mb-4">
                Dados de pré-natal
              </h3>
            </div>

            <div class="col-md-12 mt-6">
              <validation-observer
                class="mt-6"
                ref="observer"
                v-slot="{ invalid }"
              >
                <form @submit.prevent="atualizarPaciente">
                  <div class="row">
                    <div class="col-md-6 mt-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Risco gravidez"
                        rules="required"
                      >
                        <b-form-select
                          style="border-color: black"
                          v-model="risco_gravidez"
                          :error-messages="errors"
                          :options="options"
                          required
                        ></b-form-select>
                      </validation-provider>
                    </div>

                    <div class="col-md-6 mt-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Tipo de gravidez"
                        rules="required"
                      >
                        <b-form-select
                          style="border-color: black"
                          v-model="tipo_gravidez"
                          :error-messages="errors"
                          :options="options2"
                          required
                        ></b-form-select>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4 mt-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="DUM"
                        rules="required"
                      >
                        <v-text-field
                          type="date"
                          v-model="dum"
                          label="DUM (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div class="col-md-4 mt-4">
                      <validation-provider name="DPP">
                        <v-text-field
                          type="date"
                          v-model="dpp"
                          label="DPP (Opcional)"
                        ></v-text-field>
                      </validation-provider>
                    </div>

                    <div class="col-md-4 mt-4">
                      <validation-provider name="DPP eco">
                        <v-text-field
                          type="date"
                          v-model="dpp_eco"
                          label="DPP eco (Opcional)"
                        ></v-text-field>
                      </validation-provider>
                    </div>
                  </div>

                  <div
                    class="row mt-4"
                    style="margin-left: 0.1rem; margin-right: 0.1rem"
                  >
                    <div
                      class="col-md-12 mt-6"
                      style="background-color: rgb(29, 209, 161)"
                    >
                      <h3 style="float: left" class="textTitile mt-4 mb-4">
                        Dados de gestação
                      </h3>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="G"
                        rules="required"
                      >
                        <v-text-field
                          v-model="g"
                          label="G (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="p"
                        rules="required"
                      >
                        <v-text-field
                          v-model="p"
                          label="p (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="PN"
                        rules="required"
                      >
                        <v-text-field
                          v-model="pn"
                          label="PN (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="PC"
                        rules="required"
                      >
                        <v-text-field
                          v-model="pc"
                          label="PC (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>

                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="A"
                        rules="required"
                      >
                        <v-text-field
                          v-model="a"
                          label="A (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>

                    <div class="col-md-4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Espontâneo"
                        rules="required"
                      >
                        <v-text-field
                          v-model="espotaneo"
                          label="Espontâneo (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Provocado"
                        rules="required"
                      >
                        <v-text-field
                          v-model="provocado"
                          label="Provocado (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                    <div class="col-md-6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Natimorto"
                        rules="required"
                      >
                        <v-text-field
                          v-model="natimorto"
                          label="Natimorto (Obrigatório)"
                          :error-messages="errors"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <validation-provider name="Término da última gestação">
                        <v-text-field
                          type="date"
                          v-model="terminal_gestacao"
                          label="Última gestação (Opcional)"
                        ></v-text-field>
                      </validation-provider>
                    </div>

                    <div class="col-md-6">
                      <validation-provider name="Óbito neonatal">
                        <v-text-field
                          v-model="obito_neonatal"
                          label="Óbito neonatal (Opcional)"
                        ></v-text-field>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-12">
                      <v-btn
                        @click="createPreNatal"
                        style="float: right"
                        :disabled="invalid"
                        class="mt-4 textStyle"
                        tile
                        color="#1dd1a1"
                      >
                        <span style="color: white">Cadastrar</span>
                      </v-btn>
                    </div>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--end::prontuario-->

    <div class="row">
      <div class="col-md-12">
        <v-btn
          v-b-modal.modal-novo-prenatal
          style="float: right"
          :disabled="invalid"
          class="mt-4 textStyle"
          tile
          color="#1dd1a1"
        >
          <span style="color: white">Cadastrar novo Pré-natal</span>
        </v-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-tabs fill justified tabs card>
          <b-tab
            v-for="(preNatal, index) in listPreNatais"
            :key="preNatal.id"
            :active="index == 0 ? true : false"
            :title="`Pré-natal - ${index + 1}`"
            @click="atualizarIdPreNatal(preNatal.id)"
          >
            <div>
              <div class="row">
                <div class="col-md-12 mt-4">
                  <div class="row">
                    <div
                      style="background-color: rgb(29, 209, 161)"
                      class="col-md-12"
                    >
                      <h3 style="float: left" class="textTitile mt-4 mb-4">
                        Dados de pré-natal
                      </h3>
                    </div>

                    <div class="col-md-12 mt-6">
                      <validation-observer class="mt-6" ref="observer">
                        <form @submit.prevent="atualizarPaciente">
                          <div class="row">
                            <div class="col-md-6 mt-4">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Risco gravidez"
                                rules="required"
                              >
                                <b-form-select
                                  style="border-color: black"
                                  :value="preNatal.risco_gravidez"
                                  :error-messages="errors"
                                  :options="options"
                                  required
                                ></b-form-select>
                              </validation-provider>
                            </div>

                            <div class="col-md-6 mt-4">
                              <validation-provider
                                v-slot="{ errors }"
                                name="Tipo de gravidez"
                                rules="required"
                              >
                                <b-form-select
                                  style="border-color: black"
                                  :value="preNatal.tipo_gravidez"
                                  :error-messages="errors"
                                  :options="options2"
                                  required
                                ></b-form-select>
                              </validation-provider>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-4 mt-4">
                              <validation-provider name="DUM">
                                <v-text-field
                                  type="date"
                                  :value="preNatal.dum"
                                  label="DUM "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-4 mt-4">
                              <validation-provider name="DPP">
                                <v-text-field
                                  type="date"
                                  :value="preNatal.dpp"
                                  label="DPP "
                                ></v-text-field>
                              </validation-provider>
                            </div>

                            <div class="col-md-4 mt-4">
                              <validation-provider name="DPP eco">
                                <v-text-field
                                  type="date"
                                  :value="preNatal.dpp_eco"
                                  label="DPP eco "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                          </div>

                          <div
                            class="row mt-4"
                            style="margin-left: 0.1rem; margin-right: 0.1rem"
                          >
                            <div
                              class="col-md-12 mt-6"
                              style="background-color: rgb(29, 209, 161)"
                            >
                              <h3
                                style="float: left"
                                class="textTitile mt-4 mb-4"
                              >
                                Dados de gestação
                              </h3>
                            </div>
                          </div>

                          <div class="row mt-4">
                            <div class="col-md-2">
                              <validation-provider name="G">
                                <v-text-field
                                  :value="preNatal.g"
                                  label="G "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-2">
                              <validation-provider name="p">
                                <v-text-field
                                  :value="preNatal.p"
                                  label="p "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-3">
                              <validation-provider name="PN">
                                <v-text-field
                                  :value="preNatal.pn"
                                  label="PN "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-3">
                              <validation-provider name="PC">
                                <v-text-field
                                  :value="preNatal.pc"
                                  label="PC "
                                ></v-text-field>
                              </validation-provider>
                            </div>

                            <div class="col-md-2">
                              <validation-provider name="A">
                                <v-text-field
                                  :value="preNatal.a"
                                  label="A "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-2">
                              <validation-provider name="Espontâneo">
                                <v-text-field
                                  :value="preNatal.espotaneo"
                                  label="Espontâneo"
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-2">
                              <validation-provider name="Provocado">
                                <v-text-field
                                  label="Provocado "
                                  :value="preNatal.provocado"
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-2">
                              <validation-provider name="Natimorto">
                                <v-text-field
                                  :value="preNatal.natimorto"
                                  label="Natimorto "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-3">
                              <validation-provider name="Óbito neonatal">
                                <v-text-field
                                  :value="preNatal.obito_neonatal"
                                  label="Óbito neonatal (Opcional)"
                                ></v-text-field>
                              </validation-provider>
                            </div>

                            <div class="col-md-3">
                              <validation-provider
                                name="Término da última gestação"
                              >
                                <v-text-field
                                  type="date"
                                  :value="preNatal.terminal_gestacao"
                                  label="Última gestação "
                                ></v-text-field>
                              </validation-provider>
                            </div>
                          </div>

                          <!--  falta implementar um update com popup
                        <div class="row mb-4">
                          <div class="col-md-12">
                            <v-btn
                              @click="updatePreNatal"
                              style="float: right"
                              :disabled="invalid"
                              class="mt-4 textStyle"
                              tile
                              color="#1dd1a1"
                            >
                              <span style="color: white">Atualizar</span>
                            </v-btn>
                          </div>
                        </div>
                               -->
                        </form>
                      </validation-observer>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Começando a parte das consultas -->
              <div class="mb-6 mt-6">
                <div class="row">
                  <div
                    style="background-color: rgb(29, 209, 161)"
                    class="col-md-12"
                  >
                    <h3 style="float: left" class="textTitile mt-4 mb-4">
                      Histórico de gestação atual
                    </h3>
                    <b-button
                      @click="resetarCampos(preNatal.id)"
                      variant="success"
                      style="
                        font-size: 1.2rem;
                        margin-left: 0px;
                        margin-top: 0.2rem;
                        border-radius: 0px;
                        border: 2px solid white;
                        color: white;
                      "
                      v-b-modal.gestacao-1
                      >Nova Consulta de gestação</b-button
                    >
                  </div>
                </div>
              </div>

              <div class="row mt-6">
                <div
                  style="
                    display: flex;
                    align-itens: center;
                    justify-content: space-between;
                  "
                  class="col-md-12"
                >
                  <b-tooltip :target="`tooltip3-target`" triggers="hover">
                    Página anterior
                  </b-tooltip>
                  <b-tooltip :target="`tooltip4-target`" triggers="hover">
                    Próxima página
                  </b-tooltip>

                  <div class="mt-2">
                    <h3>Página {{ page2 }} de {{ totalPages2 }}</h3>
                  </div>

                  <div>
                    <v-btn
                      :disabled="page2 == 0 || page2 == 1 ? true : false"
                      @click="anteriorPage2"
                      id="tooltip3-target"
                      color="#1dd1a1"
                      class="mr-1"
                    >
                      <v-icon center small color="#FFF"
                        >fas fa-arrow-left</v-icon
                      ></v-btn
                    >
                    <v-btn
                      :disabled="
                        page2 == totalPages2 || totalPages2 == 0 ? true : false
                      "
                      @click="proximoPage2"
                      id="tooltip4-target"
                      color="#1dd1a1"
                    >
                      <v-icon center small color="#FFF"
                        >fas fa-arrow-right</v-icon
                      ></v-btn
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="">Data</th>
                          <th class="">Queixa</th>
                          <th class="">IG -DUM/USG</th>
                          <th class="">Peso (kg)/IMC</th>
                          <th class="">Edema</th>
                          <th class="">Pressão arerial (mmHg)</th>
                          <th class="">Altura uterina (cm)</th>
                          <th class="">Apresentação fetal</th>
                          <th class="">BCF / Mov. fetal</th>
                          <th class="">Toque, se indicado</th>
                          <th class="">Exantema</th>
                          <th class="">Opções</th>
                        </tr>
                      </thead>

                      <tbody
                        v-for="gestacao in tempGestacoes"
                        :key="gestacao.id"
                      >
                        <tr>
                          <td>{{ gestacao.data }}</td>
                          <td>
                            {{ gestacao.queixa }}
                          </td>

                          <td>
                            {{ gestacao.ig_dum_usg }}
                          </td>

                          <td>
                            {{ gestacao.peso }}
                          </td>

                          <td>
                            {{ gestacao.edema }}
                          </td>

                          <td>
                            {{ gestacao.pressao_arterial }}
                          </td>

                          <td>
                            {{ gestacao.altura_uterina }}
                          </td>

                          <td>
                            {{ gestacao.apresentacao_fetal }}
                          </td>
                          <td>
                            {{ gestacao.bcf_mov_fetal }}
                          </td>
                          <td>
                            {{ gestacao.toque_se_indicado }}
                          </td>
                          <td>
                            {{ gestacao.exantema }}
                          </td>
                          <td>
                            <v-btn
                              @click="sendDadosModel(gestacao.id)"
                              v-b-modal.gestacao-update
                              v-b-tooltip.hover.top="'Atualizar gestação atual'"
                              class="ml-4 mb-2 mt-6"
                              color="#1dd1a1"
                            >
                              <span style="color: white"></span>
                              <v-icon center small color="#FFF"
                                >fas fa-edit</v-icon
                              ></v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div
                    v-if="tempGestacoes.length == 0"
                    class="d-flex flex-column-fluid flex-center mt-12"
                  >
                    <a href="#" class="text-center mb-10">
                      <img
                        src="media/novaImagens/logo.png"
                        class="max-h-200px"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";

import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido"
});
export default {
  name: "prontuario",
  components: { ValidationProvider, ValidationObserver },
  props: {
    dados: Object
  },
  async created() {
    try {
      this.loasdPreNatal();

      const { data } = await ambulatorio.getPreNatalByProntuario(
        this.dados.dados_prontuario.id
      );

      const primeiraPre = data?.pre_natal[0].id;
      this.idPreNatalAtual = data?.pre_natal[0].id;

      this.loadGestacao(primeiraPre, 1);
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      idPreNatalAtual: "",
      listPreNatais: [],
      page2: 1,
      totalPages2: 0,
      consultaDataUm: "",
      consultaDataDois: "",
      idGestacaoAtual: "",
      gestacoes: [],
      tempGestacoes: [],
      bfcMovFetal: "",
      pressaoArterial: "",
      apresentacaoFetal: "",
      alturaUterina: "",
      queixa: "",
      dataGestacao: "",
      peso: "",
      igDum: "",
      toqueIndicado: null,
      exantema: null,
      edema: null,
      optionsGestacao: [
        { value: null, text: "Selecione uma opção (Obrigatório)" },
        { value: "Sim", text: "Sim" },
        { value: "Não", text: "Não" }
      ],
      idPreNatal: "",
      risco_gravidez: null,
      tipo_gravidez: null,
      dum: "",
      dpp: "",
      dpp_eco: "",
      g: "",
      p: "",
      pn: "",
      pc: "",
      a: "",
      espotaneo: "",
      provocado: "",
      natimorto: "",
      obito_neonatal: "",
      terminal_gestacao: "",
      prontuario_id: "",
      options: [
        { value: null, text: "Seleciona o risco de gravidez (Obrigatório)" },
        { value: true, text: "Sim" },
        { value: false, text: "Não" }
      ],
      options2: [
        { value: null, text: "Seleciona o tipo de gravidez (Obrigatório)" },
        { value: "Única", text: "Única" },
        { value: "Gemelar", text: "Gemelar" }
      ],
      totalPages: 1,
      page: 1,
      natais: [],
      tempNatais: [],
      consulta: ""
    };
  },

  methods: {
    atualizarIdPreNatal: function(idPreNatal) {
      this.idPreNatalAtual = idPreNatal;

      this.loadGestacao(idPreNatal, 1);
    },
    updateGestacao: async function() {
      try {
        const data = {
          gestacao_atual: {
            data: this.dataGestacao,
            queixa: this.queixa,
            ig_dum_usg: this.igDum,
            edema: this.edema,
            bcf_mov_fetal: this.bfcMovFetal,
            toque_se_indicado: this.toqueIndicado,
            exantema: this.exantema,
            peso: this.peso,
            pressao_arterial: this.pressaoArterial,
            apresentacao_fetal: this.apresentacaoFetal,
            altura_uterina: this.alturaUterina,
            pre_natal: this.idPreNatalAtual
          }
        };

        await ambulatorio.updateGestacaoAtual(data, this.idGestacaoAtual);

        this.hideModalUpdate();

        this.loadGestacao(this.idPreNatalAtual, 1);

        Swal.fire({
          title: "",
          text: "Gestação atualizada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });

        console.log(data);
      } catch (error) {
        console.log(error);

        Swal.fire({
          title: "",
          text: "Erro ao atualizar gestação atual, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },
    createPreNatal: async function() {
      try {
        const data = {
          pre_natal: {
            risco_gravidez: this.risco_gravidez,
            tipo_gravidez: this.tipo_gravidez,
            dum: this.dum,
            dpp: this.dpp,
            dpp_eco: this.dpp_eco,
            g: this.g,
            p: this.p,
            pn: this.pn,
            pc: this.pc,
            a: this.a,
            espotaneo: this.espotaneo,
            provocado: this.provocado,
            natimorto: this.natimorto,
            obito_neonatal: this.obito_neonatal,
            terminal_gestacao: this.terminal_gestacao,
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.createPreNatal(data);

        this.loasdPreNatal();

        this.hideModalNovoPrenatal();

        Swal.fire({
          title: "",
          text: "Informações de pré natal salvas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar salvamento, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },
    updatePreNatal: async function() {
      try {
        const data = {
          pre_natal: {
            risco_gravidez: this.risco_gravidez,
            tipo_gravidez: this.tipo_gravidez,
            dum: this.dum,
            dpp: this.dpp,
            dpp_eco: this.dpp_eco,
            g: this.g,
            p: this.p,
            pn: this.pn,
            pc: this.pc,
            a: this.a,
            espotaneo: this.espotaneo,
            provocado: this.provocado,
            natimorto: this.natimorto,
            obito_neonatal: this.obito_neonatal,
            terminal_gestacao: this.terminal_gestacao,
            prontuario_id: this.dados.dados_prontuario.id
          }
        };

        await ambulatorio.updatePreNatal(this.idPreNatal, data);

        Swal.fire({
          title: "",
          text: "Informações de pré natal atualizadas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar atualização, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },
    loasdPreNatal: async function() {
      try {
        const { data } = await ambulatorio.getPreNatalByProntuario(
          this.dados.dados_prontuario.id
        );

        this.listPreNatais = data.pre_natal;
      } catch (error) {
        console.log(error);
      }
    },

    loadGestacao: async function(idPreNatal, page) {
      try {
        const { data } = await ambulatorio.getGestacaoAtualProntuario(
          idPreNatal,
          page
        );

        this.totalPages2 = data.total_pages;
        this.tempGestacoes = data.gestacao_atual;
        this.gestacoes = data.gestacao_atual;
      } catch (error) {
        console.log(error);
      }
    },
    consultaPreNatal: async function() {
      if (this.consulta == "" || this.consulta == " ") {
        const { data } = await ambulatorio.historicoPreNatal(
          this.dados.dados_prontuario.id,
          1
        );

        this.natais = data.histórico_nascimento;
        this.tempNatais = data.histórico_nascimento;
        this.totalPages = data.total_pages;
      } else {
        const data = {
          filter: {
            created_at: this.consulta
          },
          required: {
            prontuario_id: this.dados.dados_prontuario.id,
            created_at: []
          },
          page: 1,
          size: 10
        };

        const response = await ambulatorio.buscaResultadoPreNatal(data);

        this.natais = response.data.resultado;
        this.tempNatais = response.data.resultado;
        this.totalPages = response.data.total_pages;
      }
    },
    proximoPage: async function() {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.historicoPreNatal(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.natais = response.data.histórico_nascimento;
          this.tempNatais = response.data.histórico_nascimento;
          this.totalPages = response.data.total_pages;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function() {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.historicoPreNatal(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.natais = response.data.histórico_nascimento;
          this.tempNatais = response.data.histórico_nascimento;
          this.totalPages = response.data.total_pages;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },

    proximoPage2: async function() {
      try {
        this.page2++;
        if (this.page2 <= this.totalPages2) {
          this.loadGestacao(this.idPreNatalAtual, this.page2);
        } else {
          this.page2--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage2: async function() {
      try {
        this.page2--;
        if (this.page2 >= 1) {
          this.loadGestacao(this.idPreNatalAtual, this.page2);
        } else {
          this.page2++;
        }
      } catch (error) {
        console.log(error);
      }
    },
    hideModal() {
      this.$refs["modal-gestacao"].hide();
    },

    hideModalUpdate() {
      this.$refs["modal-gestacaoUpdate"].hide();
    },

    hideModalNovoPrenatal() {
      this.$refs["modal-novo-prenatal"].hide();
    },

    sendDadosModel: async function(idGestacao) {
      try {
        this.idGestacaoAtual = idGestacao;

        const {
          data: { gestacao_atual }
        } = await ambulatorio.showGestacaoAtual(this.idGestacaoAtual);

        console.log(gestacao_atual);
        this.dataGestacao = gestacao_atual.data;
        this.queixa = gestacao_atual.queixa;
        this.igDum = gestacao_atual.ig_dum_usg;
        this.edema = gestacao_atual.edema;
        this.bfcMovFetal = gestacao_atual.bcf_mov_fetal;
        this.toqueIndicado = gestacao_atual.toque_se_indicado;
        this.exantema = gestacao_atual.exantema;
        this.peso = gestacao_atual.peso;
        this.pressaoArterial = gestacao_atual.pressao_arterial;
        this.apresentacaoFetal = gestacao_atual.apresentacao_fetal;
        this.alturaUterina = gestacao_atual.altura_uterina;
        this.dados.dados_prontuario.id = gestacao_atual.prontuario_id;
      } catch (error) {
        console.log(error);
      }
    },
    cadastrarGestacao: async function() {
      try {
        const data = {
          gestacao_atual: {
            data: this.dataGestacao,
            queixa: this.queixa,
            ig_dum_usg: this.igDum,
            edema: this.edema,
            bcf_mov_fetal: this.bfcMovFetal,
            toque_se_indicado: this.toqueIndicado,
            exantema: this.exantema,
            peso: this.peso,
            pressao_arterial: this.pressaoArterial,
            apresentacao_fetal: this.apresentacaoFetal,
            altura_uterina: this.alturaUterina,
            pre_natal_id: this.idPreNatalAtual
          }
        };

        await ambulatorio.createGestacaoAtual(data);

        this.hideModal();

        this.loadGestacao(this.idPreNatalAtual, 1);
        this.loadGestacao(this.idPreNatalAtual, 1);

        Swal.fire({
          title: "",
          text: "Nova gestação cadastrada com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false
        });

        console.log(data);
      } catch (error) {
        console.log(error);

        Swal.fire({
          title: "",
          text: "Erro ao cadastrar gestação atual, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false
        });
      }
    },
    resetarCampos: function(idPreNatal) {
      this.idPreNatalAtual = idPreNatal;

      this.dataGestacao = "";
      this.queixa = "";
      this.igDum = "";
      this.edema = null;
      this.bfcMovFetal = "";
      this.toqueIndicado = null;
      this.exantema = null;
      this.peso = "";
      this.pressaoArterial = "";
      this.apresentacaoFetal = "";
      this.alturaUterina = "";
    },

    hideModal2() {
      this.$refs["filter-modal"].hide();
    },

    consultarPorIntervalo: async function() {
      try {
        const data = {
          filter: {
            created_at: "",
            "pessoas.nome": this.dados.dados_paciente.pessoa.nome,
            "prontuarios.numero": this.dados.dados_paciente.prontuario,
            "pessoas.cpf": this.dados.dados_paciente.pessoa.cpf
          },
          required: {
            created_at: [this.consultaDataUm, this.consultaDataDois]
          },
          page: 1,
          size: 10
        };

        const response = await ambulatorio.buscaResultadoPreNatal(data);

        this.natais = response.data.resultado;
        this.tempNatais = response.data.resultado;
        this.totalPages = response.data.total_pages;

        this.hideModal2();
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário " }]);
  }
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}

.theme--light.v-messages {
  color: red !important ;
}

.modal-content {
  width: 140% !important;
}
</style>
